@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&family=Montserrat&display=swap);
html, body { 
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #f2f2f2;
}
  
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
  
p + p {
    margin-top: 0;
}
  
h1 {
  text-align: center;
  font-family: "Hind", sans-serif;
  font-size: 60px;
  margin-bottom: 10px; }

.App {
  width: 100%;
  height: 100%;
  color: #404040;
  background: #f2f2f2;
  font-family: "Raleway"; }

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
  width: 100%;
  color: #404040;
  text-align: center;
  font-weight: bold;
  height: 60px;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  display: flex; }

.nav-logo {
  font-family: "Raleway";
  display: flex;
  align-items: center;
  height: 100%;
  color: #249ada;
  font-size: 22px;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: 0px 20px 0px 20px; }

.nav-links-wrapper {
  display: flex; }

.nav-link, .active-link {
  font-family: "Raleway";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  max-width: 100px;
  color: inherit;
  text-decoration: none;
  transition: 0.1s; }

.nav-link:hover, .active-link:hover {
  color: #249ada;
  font-size: 1.3em;
  transition: 0.1s; }

.active-link {
  color: #249ada;
  font-size: 1.3em;
  text-decoration: none; }

@keyframes fadeInAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.page {
  animation: fadeInAnimation ease 0.5s;
  background: #f2f2f2;
  margin-top: 60px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px - 80px); }

.projects-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }

.project-card {
  background-color: #f5f5f5;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 8px;
  display: flex;
  height: 220px;
  width: 500px;
  max-width: 90%;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  transition: 0.2s; }
  .project-card .info-bar {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between; }
  .project-card h2 {
    margin: 10px 0px 10px 0px; }
  .project-card img {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    width: 220px;
    height: 220px;
    border-radius: 8px; }
  .project-card div {
    padding: 10px; }

.project-card:hover {
  background-color: rgba(215, 215, 215, 0.5);
  transition: 0.2s; }

.project-modal {
  animation: fadeInAnimation ease 0.2s;
  overflow: scroll;
  visibility: visible;
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  padding-top: 85px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  display: flex;
  justify-content: center; }
  .project-modal.off {
    visibility: visible; }
  .project-modal .content-wrapper {
    background-color: rgba(252, 252, 252, 0.85);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    width: 600px;
    max-width: 95%;
    height: 80vh;
    max-height: 80vh;
    padding: 30px;
    margin: 0px 15px 0px 15px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    color: black;
    font-size: 1.2em; }
    .project-modal .content-wrapper .content {
      display: flex;
      flex-direction: column; }
      .project-modal .content-wrapper .content .exit-link {
        font-size: 40px;
        text-decoration: none;
        color: inherit;
        transition: 0.1s;
        opacity: 0.7; }
        .project-modal .content-wrapper .content .exit-link :hover {
          opacity: 1 !important;
          color: red;
          transition: 0.1s; }
      .project-modal .content-wrapper .content .section-heading {
        font-weight: bold;
        border-bottom: 1px solid black;
        margin: 5px 0px 5px 0px; }
      .project-modal .content-wrapper .content :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px; }
        .project-modal .content-wrapper .content :nth-child(1) h2 {
          margin: 0px; }
        .project-modal .content-wrapper .content :nth-child(1) button {
          font-size: 2em;
          background: none;
          cursor: pointer;
          font-family: inherit;
          border-radius: 8px;
          border: none;
          font-weight: bold;
          height: 35px;
          width: 35px; }
      .project-modal .content-wrapper .content .carousel {
        overflow-x: scroll;
        display: flex;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
        height: 420px;
        width: 100%; }
        .project-modal .content-wrapper .content .carousel img {
          height: 400px;
          width: auto;
          margin: 10px;
          border-radius: 8px; }
      .project-modal .content-wrapper .content .more {
        padding: 10px 0px 10px 0px;
        display: flex; }
        .project-modal .content-wrapper .content .more a {
          margin: 0px 5px 0px 5px; }

.profilepic {
  border-radius: 50%;
  height: 300px;
  width: 300px;
  margin: 20px; }

.text-container {
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  max-width: 90%;
  line-height: 1.5;
  font-size: 18px;
  color: #404040;
  font-family: "Raleway"; }
  .text-container h2 {
    color: #404040;
    font-weight: bold;
    font-size: 36px;
    text-align: center; }
  .text-container p {
    margin: 12px; }
  .text-container ul {
    margin: 10px;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    max-width: 65%;
    text-align: left; }
  .text-container li {
    margin-bottom: 10px; }

@media screen and (max-width: 550px) {
  .header {
    position: fixed;
    justify-content: space-between; }
    .header button {
      background: none;
      border: none;
      width: 60px;
      height: 60px;
      cursor: pointer; }
  .nav-links-collapse, .slide-out, .slide-in {
    background-color: #f5f5f5;
    width: 100%;
    color: #404040;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: fixed;
    z-index: 101 !important;
    top: 60px;
    left: 0px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%); }
  .slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards; }
  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards; }
  @keyframes slide-out {
    100% {
      transform: translateY(0%); } }
  @keyframes slide-in {
    0% {
      transform: translateY(0%); }
    100% {
      transform: translateY(-100%); } }
  .nav-link, .active-link {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 20px; }
  .projects-wrapper {
    width: 90%; }
  .project-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .project-card img {
      margin-top: 5px; }
  .project-modal .content-wrapper {
    margin-top: -5px;
    padding: 15px; }
    .project-modal .content-wrapper .content {
      font-size: 0.9rem; }
      .project-modal .content-wrapper .content .carousel {
        height: 330px; }
        .project-modal .content-wrapper .content .carousel img {
          height: 310px !important; } }

