html, body { 
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: #f2f2f2;
}
  
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
  
p + p {
    margin-top: 0;
}
  