@import 'colors';
@import 'fonts';
@import 'config';

h1 {
    text-align: center;
    font-family: $text-header;
    font-size: 60px;
    margin-bottom: 10px;;
}

.App {
    width: 100%;
    height: 100%;
    color: $primary-text;
    background: $primary;
    font-family: $text-regular
}

.header {
    box-shadow: 0px 1px 1px rgba(black, 0.2);
    background-color: $secondary;
    width: 100%;
    color: $primary-text;
    text-align: center;
    font-weight: bold;
    height: 60px;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    display: flex;

}
.nav-logo {
    font-family: $text-regular;
    display: flex;
    align-items: center;
    height: 100%;
    
    color: $accent-text;
    font-size: 22px;
    width: fit-content !important;
    margin: 0px 20px 0px 20px;
}

.nav-links-wrapper {
    display: flex;
}
.nav-link {
    font-family: $text-regular;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100px;
    max-width: 100px;
    color: inherit;
    text-decoration: none;
    transition: 0.1s;
}
.nav-link:hover {
    color: $accent-text;
    font-size: 1.3em;
    transition: 0.1s;
}

.active-link {
    @extend .nav-link;
    color: $accent-text;
    font-size: 1.3em;
    text-decoration: none;
}

@keyframes fadeInAnimation {
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    }
}

.page {
    animation: fadeInAnimation ease 0.5s;
    background: $primary;
    margin-top: 60px; // header height
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 60px - 80px);
}

.projects-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.project-card {
    background-color: $secondary;
    box-shadow: 0px 0px 2px rgba(black, 0.4);
    height: fit-content;
    border-radius: 8px;
    display: flex;
    height: 220px;
    width: 500px;
    max-width: 90%;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    margin: 10px 0px 10px 0px;
    transition: 0.2s;

    .info-bar {
        margin: 0px;
        padding: 0px;
        display: flex;
        justify-content: space-between;
    }

    h2 {
        margin: 10px 0px 10px 0px;
    }

    img {
        box-shadow: 0px 0px 2px rgba(black, 0.2);
        width: 220px;
        height: 220px;
        border-radius: 8px;

    }

    div {
        padding: 10px;
    }
}
.project-card:hover {
    background-color: $highlight;
    transition: 0.2s;
}

.project-modal {
    animation: fadeInAnimation ease 0.2s;
    overflow: scroll;
    visibility: visible;
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 85px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    display: flex;
    justify-content: center;

    &.off {
        visibility: visible;
    }

    .content-wrapper {
        background-color: $modal-background;
        box-shadow: 0px 0px 2px rgba(black, 0.1);
        overflow-y: scroll;
        width: 600px;
        max-width: 95%;
        height: 80vh;
        max-height: 80vh;
        padding: 30px;
        margin: 0px 15px 0px 15px;
        border: 1px solid $primary;
        border-radius: 8px;
        color: $dark-text;
        font-size: 1.2em;
        
        .content {  
            display: flex;
            flex-direction: column;

            .exit-link {
                font-size: 40px;
                text-decoration: none; 
                color: inherit;
                transition: 0.1s;
                opacity: 0.7;

                :hover {
                    opacity: 1 !important;
                    color: red;
                    transition: 0.1s;
                }
            }
    
            .section-heading {
               font-weight: bold;
               border-bottom: 1px solid black;
               margin: 5px 0px 5px 0px;
            }
    
            :nth-child(1) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
    
                h2 {
                    margin: 0px;
                }
    
                button {
                    font-size: 2em;
                    background: none;
                    cursor: pointer;
                    font-family: inherit;
                    border-radius: 8px;
                    border: none;
                    font-weight: bold;
                    height: 35px;
                    width: 35px;
                }
            }
    
            .carousel {
                overflow-x: scroll;
                display: flex;
                min-height: fit-content;
                height: 420px;
                width: 100%;
    
                img {
                    height: 400px;
                    width: auto;
                    margin: 10px;
                    border-radius: 8px;
                }
            }

            .more {
                padding: 10px 0px 10px 0px;
                display: flex;

                a {
                    margin: 0px 5px 0px 5px;
                }
            }
        }
    }
}

.profilepic {
    border-radius: 50%; 
    height: 300px; 
    width: 300px; 
    margin: 20px;
}


.text-container {
    margin: 1em ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    max-width: 90%;
    line-height: 1.5;
    font-size: 18px;
    color: $primary-text;
    font-family: $text-regular;

    h2 {
        color: $primary-text;
        font-weight: bold;
        font-size: 36px;
        text-align: center;
    }

    p {
        margin: 12px;
    }

    ul {
        margin: 10px;
        padding-inline-start: 0px;
        max-width: 65%;
        text-align: left;
    }

    li {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 550px) {
    .header {
        position: fixed;
        justify-content: space-between;

        button {
            background: none;
            border: none;
            width: 60px;
            height: 60px;
            cursor: pointer;
        }
    }

    .nav-links-collapse {
        background-color: $secondary;
        width: 100%;
        color: $primary-text;
        text-align: center;
        font-weight: bold;

        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        position: fixed;
        z-index: 101 !important;
        top: 60px;
        left: 0px;
        box-shadow: 0px 1px 1px rgba(black, 0.2);
        transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
    }

    .slide-out {
        @extend .nav-links-collapse;
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
    }
    
    .slide-in {
        @extend .nav-links-collapse;
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
        
    @keyframes slide-out {
        100% { transform: translateY(0%); }
    }
    
    @-webkit-keyframes slide-out {
        100% { -webkit-transform: translateY(0%); }
    }
        
    @keyframes slide-in {
        0% { transform: translateY(0%); }
        100% { transform: translateY(-100%); }
    }
    
    @-webkit-keyframes slide-in {
        0% { -webkit-transform: translateY(0%); }
        100% { -webkit-transform: translateY(-100%); }
    }

    .nav-link {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 20px;
    }

    .projects-wrapper {
        width: 90%;
    }
    .project-card {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;

        img {
            margin-top: 5px;
        }
    }
    .project-modal {
        .content-wrapper {
            margin-top: -5px;
            padding: 15px;

            .content {
                font-size: 0.9rem;

                .carousel {
                    height: 330px;

                    img {
                        height: 310px !important;
                    }
                }
            }
        }
    }
}